import React, { Fragment, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./inventory.css";
import mainTheme from "../../../../../configs/mainTheme";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import appParams from "../../../../../configs/app-params";

export default function Resume(props) {
  let cashRegisterInformation = props?.data?.data?.cashRegisterInformation;

  let buildResponse = {};

  const componentRef = useRef();
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let register = {};
  let totalSells = 0;

  if (cashRegisterInformation) {
    Object.keys(cashRegisterInformation).forEach((key, index) => {
      buildResponse = {
        ...buildResponse,
        [key]: cashRegisterInformation?.[key],
      };
    });

    register = buildResponse?.cashRegisterInfo;
    totalSells = buildResponse?.totalSells;
  }

  let amountByWay = Object.keys(
    buildResponse["totalSellsByPayementType"] || {}
  )?.map((sellWay) => {
    return {
      wayTitle: sellWay,
      value: buildResponse["totalSellsByPayementType"][`${sellWay}`],
    };
  });

  let amountByClientType = Object.keys(
    buildResponse["totalSellsByCustomerCategory"] || {}
  )?.map((clientCategory) => {
    return {
      categoryTitle: clientCategory,
      value: buildResponse["totalSellsByCustomerCategory"][`${clientCategory}`],
    };
  });

  let amountAdvancedByOrder = Object.keys(
    buildResponse["totalSellsByOrderAdvance"] || {}
  )?.map((advance) => {
    return {
      advanceTitle: advance,
      value: buildResponse["totalSellsByOrderAdvance"][`${advance}`],
    };
  });

  return (
    <Fragment>
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{ color: "white", bgcolor: "green", mr: 1 }}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>

      <div ref={componentRef} className="mt-2 p-2">
        <div className="depot-content">
          <div className="p-3 depot-title text-white fw-bold text-center">
            SYNTHESE DE VENTE
          </div>
          <div className="my-2 p-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableBody>
                  <TableRow
                    key={"session"}
                    sx={{ "&:last-child td, &:last-child th": { border: 5 } }}
                  >
                    <TableCell component="td" scope="session">
                      SESSION
                    </TableCell>
                    <TableCell>
                      {moment(register?.oldDate).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={"register"}
                    sx={{ "&:last-child td, &:last-child th": { border: 5 } }}
                  >
                    <TableCell component="td" scope="register">
                      CAISSIERE
                    </TableCell>
                    <TableCell>{register?.seller}</TableCell>
                  </TableRow>
                  <TableRow
                    key={"totalSells"}
                    sx={{ "&:last-child td, &:last-child th": { border: 5 } }}
                  >
                    <TableCell component="td" scope="totalSells">
                      TOTAL VENTE
                    </TableCell>
                    <TableCell>
                      {Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "XOF",
                      }).format(totalSells)}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={"openHour"}
                    sx={{ "&:last-child td, &:last-child th": { border: 5 } }}
                  >
                    <TableCell component="td" scope="openHour">
                      HEURE OUVERTURE
                    </TableCell>
                    <TableCell>
                      {moment(register?.oldDate).format("HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={"closeHour"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="closeHour">
                      HEURE FIN
                    </TableCell>
                    <TableCell>
                      {moment(register?.closeDate).format("HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="my-2 p-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      MOYEN DE REGLEMENT
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amountByWay
                    .sort((a, b) => a.value - b.value)
                    ?.map((category, key) => {
                      return (
                        <TableRow
                          key={category?.wayTitle?.toLowerCase()}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 5 },
                          }}
                        >
                          <TableCell
                            component="td"
                            scope={category?.wayTitle?.toLowerCase()}
                          >
                            {category?.wayTitle?.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {Intl.NumberFormat(appParams.language, {
                              style: "currency",
                              currency: appParams.currency,
                            }).format(category?.value)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="my-2 p-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      CATEGORIE DE CLIENT
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amountByClientType
                    .sort((a, b) => a.value - b.value)
                    ?.map((category, key) => {
                      return (
                        <TableRow
                          key={category?.categoryTitle?.toLowerCase()}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 5 },
                          }}
                        >
                          <TableCell
                            component="td"
                            scope={category?.categoryTitle?.toLowerCase()}
                          >
                            {category?.categoryTitle?.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {Intl.NumberFormat(appParams.language, {
                              style: "currency",
                              currency: appParams.currency,
                            }).format(category?.value)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="my-2 p-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      AVANCE SUR COMMANDE
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amountAdvancedByOrder
                    .sort((a, b) => b.advanceTitle - a.advanceTitle)
                    ?.map((category, key) => {
                      return (
                        <TableRow
                          key={category?.advanceTitle?.toLowerCase()}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 5 },
                          }}
                        >
                          <TableCell
                            component="td"
                            scope={category?.wayTitle?.toLowerCase()}
                          >
                            {category?.advanceTitle?.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {Intl.NumberFormat(appParams.language, {
                              style: "currency",
                              currency: appParams.currency,
                            }).format(category?.value)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="d-flex justify-content-center image-text border-top">
          <span className="image">
            <img src={mainTheme.appLogo} alt="logo" className="logo-img" />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
