import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import defaultSale from '../../../../../../assets/images/sale.png';

export default function SingleCardProduct (props) {
    
    return  <div className="cashRegister-card-information col-12 col-lg-12 col-xl-12 col-md-12 my-2">
                <div className="cashRegister-content">
                    <div className="p-1 text-center text-white cashRegister-title">
                        <h6>ARTICLE</h6>
                    </div>
                    <div className="card p-1 m-1">
                        <div className="d-flex align-items-center">
                            <div className="image bg-light p-2 mr-3">
                                <img src={props?.product.imgLabelSrc || defaultSale} alt="logo" className="rounded" width="100" height="100"/>
                            </div>
                            <div className="p-1 w-100"> 
                                <h6 className="mb-0 mt-0 text-danger">
                                  {props.product.label.toUpperCase()}
                                </h6>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                    <div className="d-flex flex-column">
                                        <small className="number1">
                                          {props.product.groupTitle.toUpperCase()}
                                        </small>
                                    </div>
                                    
                                </div>
                            </div>     
                        </div>
                        <div className="d-flex gap-2 justify-content-end px-1">
                          <button onClick={(e) => { 
                                    e.preventDefault(); 
                                    props.addToWishList(props.product.value)
                                }} 
                            type="button" className="btn btn-success btn-block  wishlist" title="Wishlist">
                              <FontAwesomeIcon icon="cart-shopping" />
                          </button>
                        </div>  
                    </div>
                  
                </div>               
            </div>
}
