import BaseBackComponent from "../base-back-component";

export default class ExpensesComponent extends BaseBackComponent {
  options = [
    {
      title: "accountingtypes",
      keys: ["libelle"],
    },
    {
      title: "accountingcategories",
      keys: ["libelle"],
    },
    {
      title: "analytiqueplans",
      keys: ["analyticalCode"],
    },
    {
      title: "chargeorproducts",
      keys: ["libelle"],
    },
    {
      title: "suppliers",
      keys: ["firstName"],
    },
    {
      title: "clients",
      keys: ["firstName", "lastName"],
    },
    {
      title: "departements",
      keys: ["name"],
    },
  ];

  name = "Expenses";

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      libelle: null,
      accountingTypeId: null,
    },
  };

  componentDidMount() {
    this.initDataLoading();
    this.loadOptions();
  }

  async enableOrDisableField(fieldName, value) {
    await this.setState((state) => ({
      formPayload: {
        ...state.formPayload,
        [fieldName]: {
          ...state.formPayload.secondFinishProductDepot,
          disable: value,
          value: null,
        },
      },
    }));
  }

  async otherHandleFieldTreatment(name, value) {
    if (name === "accountingTypeId") {
      if (value) {
        let accountingTypes = this.state.originalOptionsData?.accountingtypes;

        let accountingType = accountingTypes.find((type) => type.id === value);

        if (accountingType && accountingType.libelle === "CHARGE") {
          await this.enableOrDisableField("customerId", true);
          await this.enableOrDisableField("supplierId", false);
        } else if (accountingType && accountingType.libelle === "PRODUIT") {
          await this.enableOrDisableField("customerId", false);
          await this.enableOrDisableField("supplierId", true);
        } else {
          await this.enableOrDisableField("customerId", false);
          await this.enableOrDisableField("supplierId", false);
        }
      } else {
        await this.enableOrDisableField("customerId", false);
        await this.enableOrDisableField("supplierId", false);
      }
    }
  }

  otherSingleDataInit(data) {
    data = {
      ...data,
      accountingTypeId: { value: data?.accountingType?.value?.id },
      accountingCategoryId: { value: data?.accountingCategory?.value?.id },
      customerId: {
        value: data?.customer?.value?.id,
        disable: data?.accountingType?.value.libelle === "CHARGE",
      },
      supplierId: {
        value: data?.supplier?.value?.id,
        disable: data?.accountingType?.value.libelle === "PRODUIT",
      },
      analyticalPlanId: { value: data?.analyticalPlan?.value?.id },
    };
    return data;
  }
}
