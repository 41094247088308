import React, { createRef, Fragment } from 'react';
import './style.css';
import { Autocomplete, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const paymentWay =  [
    {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
    {value: 'BANQUE', label: 'BANQUE'},
    {value: 'CASH', label: 'CASH'},
    {value: 'CREDIT', label: 'CREDIT'},
  ]

export default class SellRecap extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
        currency : 0,
        amount : 0,
        paymentWay : null,
        clientInformation : null,
        payFormVisible : true,
        amountError : null,
        amountInputDisable: false,
        amountEmitted : null,
        inLoad : false,
        hideField : false
    }

    this.updateAmountAndCurrency = this.updateAmountAndCurrency.bind(this);
    this.changePaymentWay = this.changePaymentWay.bind(this);
    this.updateAmountAndCurrency = this.updateAmountAndCurrency.bind(this);
    this.proceedToRegister = this.proceedToRegister.bind(this);
    this.secondScreenRef = createRef();
    this.openSecondScreen = this.openSecondScreen.bind(this);
    
  }

  openSecondScreen = async (title) => {
    const total =  this.props.recapInformation.totalAmount.toLocaleString('fr-FR') || 0;

    try {
      await axios.post('https://pikado.gestikenzo.com/open-vfd/', { total });
    } catch (error) {
      console.error('Erreur lors de l\'envoi du total', error);
    }

  }

  componentDidMount(){
    this.openSecondScreen("TOTAL FACTURE");
    this.setState({inLoad : false,hideField: false});
    
    const clientInformation = this.props.recapInformation.clientInformation.originalObject;
    let newState = {
      clientInformation : clientInformation
    };

    if(clientInformation.category.libelle.toUpperCase() === "CLIENT INTERNE"){
      newState = {
        ...newState,
        payFormVisible : false,
        paymentWay :  {value: 'CREDIT', label: 'CREDIT'},
      }
    }else{
      newState = {
        ...newState,
        payFormVisible : true,
        paymentWay :  {value: 'CASH', label: 'CASH'},
      }
    }
    this.setState(newState);
  }
  

  async changePaymentWay(value){
    await this.setState({
                    paymentWay : value, 
                    amountInputDisable : value?.label?.toLowerCase() === "credit",
                    amount: value?.label?.toLowerCase() === "credit" ? "0" : "",
                    amountEmitted: value?.label?.toLowerCase() === "credit" ? "0" : "",
                    hideField : value?.label?.toLowerCase() === "credit"
                  });
  }
  
  updateAmountAndCurrency(amount){
    const currency = amount - this.props.recapInformation.totalAmount;
    this.setState({
      amountEmitted: amount,
      amount : amount,
      currency : currency > 0 ? currency : 0
    });

  }

  proceedToRegister(){
    let paymentWay  = {value: 'CASH', label: 'CASH'};
    this.setState({inLoad : true});


    if(this.state.clientInformation.category.libelle.toLowerCase() !== "client interne" && this.state.paymentWay.value.toUpperCase() !== "CREDIT"){
      
      if(!this.state.amount || this.state.amount === 0){
        this.setState({amountError : "Veuillez renseignez le montant svp"});
      }else if(this.state.amount < this.props.recapInformation.totalAmount){
        this.setState({amountError : "Le montant recupérer doit être supérieure au montant total"});
      }else{
        this.props.proceedToSellRegistration({
          amountPaid : this.state.amount,
          paymentWay : this.state.paymentWay || paymentWay,
          customer : this.state.clientInformation.id,
          validWishList:  this.props.recapInformation?.selectedProduct
        });
      }
    }else{
      paymentWay = {value : "CREDIT", label: "CREDIT"}
      this.props.proceedToSellRegistration({
        amountPaid : this.state.amount,
        paymentWay : this.state.paymentWay || paymentWay,
        customer : this.state.clientInformation.id ,
        validWishList:  this.props.recapInformation?.selectedProduct
      });
    }
   
    setTimeout(
      () => {
        this.setState({inLoad : false});
      },3000
    )
    
  }

  render () {

    return (
      <div className="container">
        <div className="title text-white p-3 my-1">
            <div className='row'>
              <div className='col-6 d-flex justify-content-start'>
                <h5 className='mx-1 mt-1'>
                    VALIDATION DE PAIEMENT
                </h5> 
              </div>
                    
                <div className="col-6 d-flex justify-content-end">
                  <button
                    onClick={this.props.backToBuildInformation}
                    className="btn btn-sm mx-1 btn-secondary"
                  >
                    <FontAwesomeIcon icon="angle-double-left"/>
                    <span className='mx-1'>Retour au panier </span> 
                  </button>
                  
                </div>
            </div>

           
        </div>
        <div className="row">
          <div className="col-12 col-xl-7">
            {
                this.props.recapInformation?.selectedProduct?.map(
                    product => <div key={product.value} className="card border shadow-none">
                                  <div className="card-body">
                    
                                    <div className="d-flex align-items-start border-bottom pb-3">
                                      <div className="me-4">
                                        <img
                                          src={product.imgLblSrc || "https://www.bootdey.com/image/380x380/008B8B/000000"}
                                          alt=""
                                          className="avatar-lg rounded"
                                        />
                                      </div>
                                      <div className="flex-grow-1 align-self-center overflow-hidden">
                                        <div>
                                          <h5 className="text-truncate font-size-16">
                                            <span className="text-dark">
                                              {product.label.toUpperCase()}
                                            </span>
                                          </h5>
                                          
                                          <p className="mb-0 mt-1">
                                            <small className="fw-medium text-muted">                                
                                                {product.groupTitle.toUpperCase()}
                                            </small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                    
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="d-flex">
                                            <small className="text-muted">P.U. : </small>
                                            <h5 className="mx-1">
                                            {product.unitPrice.toLocaleString('fr-FR')}

                                            </h5>
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="d-flex">
                                            <small className="text-muted">Qté : </small>
                                            <h5 className="mx-1">
                                              {product.qty.toLocaleString('fr-FR')}
                                            </h5>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex">
                                            <small className="text-muted">Total : </small>
                                            <h5 className="mx-1">{product.totalLineAmount.toLocaleString('fr-FR')}</h5>
                                          </div>
                                        </div>
                                      </div>
                              </div>
                            </div>
                )

            }

           
          </div>

          <div className="col-12 col-xl-5">
            <div className="mt-5 mt-lg-0">
              <div className="card border shadow-none">
                <div className="card-header title  border-bottom py-3 px-4">
                  <h5 className="font-size-16 mb-0 text-white">
                    Paiement
                  </h5>
                </div>
                <div className="card-body p-2">
                    {
                      this.state.payFormVisible ? <Fragment>

                    <div className="col-12 my-2">
                        <Autocomplete
                            value={this.state.paymentWay} 
                            id="paymentWay"
                            options={paymentWay}
                            onChange={(e,selectedOption) => {
                                this.changePaymentWay(selectedOption)
                            }}
                            getOptionLabel={option => option.label}
                            isOptionEqualToValue={(option) => option?.value === this.state.paymentWay?.value}
                            size="small"
                            fullWidth
                            renderInput={(params) => <TextField {...params} label={`Mode de paiement`}
                        />}
                      />
                    </div>
                    {
                      !this.state.hideField  ?  <div className="col-12 my-3">
                                                  <TextField
                                                      type= "number"
                                                      error={this.state.amountError}
                                                      helperText={this.state.amountError}
                                                      size="small"
                                                      fullWidth
                                                      disabled={this.state.amountInputDisable}
                                                      id="amount"
                                                      label="Montant remis"
                                                      value={this.state.amountEmitted}
                                                      onChange={(e) => { 
                                                          this.updateAmountAndCurrency(e.target.value)
                                                          }
                                                      }
                                                  />
                                              </div> : null
                    }
                   
                    <hr/>
                      </Fragment> : null
                    }
                    
                
                    <div className="col-12 p-2">
                      <div className='row bg-black p-2 my-1'>
                         <small className="col-4 d-flex justify-content-start text-white text-bold">Total :</small>   
                         <span className="col-8 d-flex justify-content-end text-white text-bold">{this.props.recapInformation.totalAmount.toLocaleString('fr-FR')}</span>   
                      </div>

                      <div className='row p-2 my-1'>
                         <small className="col-6 d-flex justify-content-start text-bold">Montant perçu :</small>   
                         <span className="col-6 d-flex justify-content-end text-bold">{this.state.amount.toLocaleString('fr-FR')}</span>   
                      </div>          

                     
                      <div className='row p-2 my-1'>
                         <small className="col-6 d-flex justify-content-start text-bold">Total produit :</small>   
                         <span className="col-6 d-flex justify-content-end text-bold">{this.props.recapInformation.productsListSize?.toLocaleString('fr-FR')}</span>   
                      </div>

                      <div className='row p-2 my-1'>
                         <small className="col-6 d-flex justify-content-start text-bold">Total quantité :</small>   
                         <span className="col-6 d-flex justify-content-end text-bold">{this.props.recapInformation.globalQty?.toLocaleString('fr-FR')}</span>   
                      </div>
                      <hr/> 
                      <div className='col-12'>
                      {
                        this.state.inLoad ? <span className='text-danger'>Veuillez patienter le temps de l'enregistrement</span> : null
                      } 
                      </div>   
                      <div className="d-flex justify-content-end mt-1 mt-sm-0 ">
                        <button
                          onClick={this.proceedToRegister}
                          className="btn btn-sm btn-success"
                          disabled={this.state.inLoad}
                        >
                           <span className='mx-1'>ENREGISTRER </span> 
                           <FontAwesomeIcon icon="check-double"/>                          
                        </button>
                      </div>
                    </div>
                </div>
               
                {/* end table-responsive */}
              </div> 
            </div>
           
          </div>
        </div>
      </div>
    );
  }
}

