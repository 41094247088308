import * as React from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts';

const otherSetting = {
    height: 500,
    width: 1000,
    grid: {horizontal: true},
    sx: {
      [`& .${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

export default function LineChartBuilder (props) {
  return (
      <LineChart
        xAxis={[
            {
              data: props?.xAxis,
              scaleType: 'point'
            },
          ]}
          colors={props.colors}
          series={props?.series}
          {...otherSetting}
      />
  );
}
