import { Button } from '@mui/material';
import './dashboard.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React,{ Fragment } from 'react';
import DashboardDetail from './_layouts/SubdashboardDataDetail/index';
import { getFixedDateInterval } from '../../../bootstrap/Helpers/utils';




const DashboardRender = props => {

  const stats = props?.renderFrameConfigs?.fetchData || [];

  const [open, setOpen] = React.useState(false);
  const [detailData, setDetailData] = React.useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setDetailData(data)
  };

  const handleClose = () => {
    setOpen(false);
    setDetailData(null)

  };

  const user = JSON.parse(localStorage.getItem("user"))?.user;

  return (
    <Fragment>
      {
        open ? 
        <Fragment>
            <DashboardDetail handleCloseDialog={handleClose} detailData={detailData} />
        </Fragment> : 
          <div className="row">
            {
              stats.map(
                stat => {

                  let subStatData = stat;
                  
                  if(stat?.name?.toUpperCase()?.includes("COMMANDE FOURNISSEUR") && !stat?.name?.toUpperCase()?.includes("LIVRAISON")){
                    subStatData.componentName = "supplier-order";
                  }else if(stat?.name?.toUpperCase()?.includes("COMMANDE FOURNISSEUR") && stat?.name?.toUpperCase()?.includes("LIVRAISON")){
                    subStatData.componentName = "supplier-deliver";
                  }


                  if(stat?.name?.toUpperCase()?.includes("TRANSFERT")){
                    subStatData.componentName = "transfer";
                  }
                  
                  if(stat?.name?.toUpperCase()?.includes("VENTE")){
                    subStatData.componentName = "sales";
                  }

                  
                  if(stat?.name?.toUpperCase()?.includes("PRODUCTION")){
                    subStatData.componentName = "production";
                  }

                  
                  if(stat?.name?.toUpperCase()?.includes("COMMANDE CLIENT") && !stat?.name?.toUpperCase()?.includes("LIVRAISON")){
                    subStatData.componentName = "customer-order";
                  }else if(stat?.name?.toUpperCase()?.includes("COMMANDE CLIENT") && stat?.name?.toUpperCase()?.includes("LIVRAISON")){
                    subStatData.componentName = "customer-deliver";
                  }
                  if(!user?.authorities?.includes(stat.authorityRequire)){
                    return null
                  }
                  return  <SingleStat openDetailDialog={handleClickOpen} key={stat.name} stat={subStatData} />
                }
              )
            }
          
          </div>
      }
      
      
    </Fragment>
  );
};


const SingleStat = props => {
  let borderColor = "warning";
  let iconBgColor = "blooker";
  let btnColor = "secondary";
  let status = "in wait";
  let period = "";

  const statName = props?.stat?.name; 


  if(statName.toLowerCase()?.includes("JOUR".toLocaleLowerCase()) || statName.toLowerCase()?.includes("AUJOURD'HUI".toLocaleLowerCase())){
    period = getFixedDateInterval("today",true);
    
    
   // moment(new Date()).format(props.stat.timeInterval) +","+moment(new Date()).format(props.stat.timeInterval)
 
    
  }

 
  if(
    (statName.toLowerCase()?.includes("Validé".toLocaleLowerCase()) 
    && 
    !statName.toLowerCase()?.includes("non".toLocaleLowerCase())) || statName.toLowerCase() === "production du jour"  || statName.toLowerCase() === "vente du jour"
  ){
    borderColor = "success";
    iconBgColor= "ohhappiness";
    btnColor = "success";
    status = "valid";

    

  }else if(
    props?.stat?.name?.toLowerCase()?.includes("Annulé".toLocaleLowerCase()) 
    || 
    props?.stat?.name?.toLowerCase()?.includes("expirées et non livrées".toLocaleLowerCase())
  ){
     borderColor = "danger";
      iconBgColor= "bloody";
      btnColor = "error";
      status = "cancel";

  }

  return <div className="col-12 col-md-3 col-xl-3 col-lg-3">
            <div className={`card radius-10 border-start border-0 border-3 border-${borderColor}`}>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary font-10">{props?.stat?.name?.toUpperCase()}</p>
                    <h5 className="my-1 text-info">{props?.stat?.statistic?.toLocaleString("fr-FR")}</h5>
                    <p className="mb-0 font-13">
                        <Button onClick={(e) => { props.openDetailDialog({
                          ...props.stat,
                          status : status,
                          period: period 
                        }) }} variant="contained" endIcon={ <FontAwesomeIcon icon="eye" color={btnColor} />}> 
                            Details
                        </Button>
                    </p>
                  </div>
                  <div className={`widgets-icons-2 rounded-circle bg-gradient-${iconBgColor} text-white ms-auto`}>
                    <FontAwesomeIcon size={15} icon="chart-area" />
                  </div>
                </div>
              </div>
            </div>
          </div>
}

export default DashboardRender;
